
import { getPhysicalCondition } from '@/api/diary';
import {
  Vue,
  Component,
  Watch,
  Prop,
} from 'vue-property-decorator';
import { Message } from 'element-ui';
import { getUserInfo } from '@/api/user';

@Component({
})

export default class extends Vue {
  @Prop({ required: true }) private diaryDate!: any;

  @Watch('diaryDate')
  private handleChangeDiaryDate() {
    this.getPhysicalCondition();
  }

  mounted() {
    this.getPhysicalCondition();
    this.getUserInfo();
  }

  private currentQuery = { ...this.$route.query };

  private getUserInfo() {
    getUserInfo().then((res) => {
      this.userInfo = res.data;
      this.currentQuery.goalDate = this.userInfo.goalDate;
      this.currentQuery.goalWeight = this.userInfo.goalWeight;
      this.currentQuery.weight = this.userInfo.weight;
    });
  }

  private userInfo = {
    goalDate: '',
    goalWeight: '',
    weight: '',
  };

  private physicalCondition = {
    weight: 0,
    muscleWeight: 0,
    fatRate: 0,
    purposeWeight: 0,
    purposeMuscleWeight: 0,
    purposeFatRate: 0,
  }

  private loading = true;

  private getPhysicalCondition() {
    this.loading = true;
    getPhysicalCondition({ searchDate: this.diaryDate }).then((res) => {
      this.physicalCondition = res.data;
      this.loading = false;
    }).then(() => {
      if (this.physicalCondition.weight === 0 || this.physicalCondition.muscleWeight === 0 || this.physicalCondition.fatRate === 0) {
        Message('체성분 상태를 기록해주세요.');
      } else if (this.physicalCondition.purposeWeight === null || this.physicalCondition.purposeMuscleWeight === null || this.physicalCondition.purposeFatRate === null) {
        Message('체성분 목표를 설정해주세요.');
      }
    });
  }
}
