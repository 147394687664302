
import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

@Component({
})

export default class extends Vue {
  @Prop({ required: true }) private data!: any;

  private warningColors = [
    { color: '#58AAD6', percentage: 80 },
    { color: '#e6a23c', percentage: 90 },
    { color: '#f56c6c', percentage: 100 },
  ]
}
