
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import Diary from './diary.vue';
import BodyComposition from './bodyComposition.vue';
import NutritionStatus from './nutritionStatus.vue';

@Component({
  components: {
    Diary,
    BodyComposition,
    NutritionStatus,
  },
})

export default class extends Vue {
  mounted() {
    if (this.$route.query.diaryDate) {
      this.diaryDate = this.$route.query.diaryDate;
    } else {
      this.$router.replace({ ...this.$router, query: { ...this.$route.query, diaryDate: this.diaryDate } });
    }
    if (this.$route.query.tab) this.type = this.$route.query.tab;
  }

  private diaryDate: any = moment().format('YYYY-MM-DD');

  private type: any = 'diary';

  private handleAddDay(days: number) {
    this.diaryDate = moment(this.diaryDate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD');
    this.$router.replace({ ...this.$router, query: { diaryDate: this.diaryDate } });
  }

  private handleChangeType(value: string) {
    this.type = value;
    this.$router.replace({ ...this.$router, query: { ...this.$route.query, tab: this.type } });
    if (value === 'body') {
      this.diaryDate = moment().format('YYYY-MM-DD');
    }
  }
}
