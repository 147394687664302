
import { deleteDiaryMeal, getDiaryMealList } from '@/api/diary';
import router from '@/router';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component({
})

export default class extends Vue {
  @Prop({ required: true }) private diaryDate!: any;

  @Watch('diaryDate')
  private handleChangeDiaryDate() {
    this.getDiaryMealList();
  }

  mounted() {
    this.getDiaryMealList();
  }

  private loading = true;

  private diaryData: any = {
    breakfast: {
      totalCalorie: 0,
      mealList: [],
    },
    lunch: {
      totalCalorie: 0,
      mealList: [],
    },
    dinner: {
      totalCalorie: 0,
      mealList: [],
    },
  }

  private type = 0;

  private formVisible = false;

  private getDiaryMealList() {
    getDiaryMealList({ searchDate: this.diaryDate }).then((res) => {
      this.diaryData = res.data;
      this.loading = false;
    });
  }

  private handleAddDiaryMeal(value: number) {
    this.type = value;
    this.formVisible = !this.formVisible;
  }

  private handleDeleteDiaryMeal(meal: any, type: string) {
    this.$confirm(`${type}식단 ${meal.menuName}(${meal.calorie}kcal)을 제거하시겠습니까?`, {
      confirmButtonText: '제거',
      cancelButtonText: '취소',
      type: 'error',
    }).then(() => {
      deleteDiaryMeal(meal.idx).then(() => {
        this.$message.success('다이어리에서 식단이 제거되었습니다.');
        this.getDiaryMealList();
      });
    });
  }
}
